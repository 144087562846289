import React from 'react'
import { Heading, Card, Box } from 'theme-ui'
import { Layout, Stack, Main, Hero } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import { useBlogCategories } from '@helpers-blog'

const styles = {
  card: {
    position: `relative`
  },
  wrapper: {
    maxWidth: 500,
    textAlign: `center`,
    mx: `auto`,
    py: 3
  },
  icons: {
    display: [`none`, null, `block`],
    position: `absolute`,
    top: `-3rem`,
    left: `5rem`,
    svg: {
      display: `block`
    }
  },
  plane: {
    fontSize: `9rem`,
    color: `beta`
  },
  wind: {
    fontSize: `7rem`,
    color: `omegaLight`,
    transform: `rotate(120deg)`,
    mt: `0.5rem`,
    ml: `-3rem`
  },
  form: {
    maxWidth: 300,
    mx: `auto`,
    mt: 4
  }
}

const Posts = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props
  const categories = useBlogCategories()
  const sliderRef = React.useRef()

  // console.log(posts)
  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Divider />
      <Stack>
        <Main>
          <Card variant='paper' sx={styles.card}>
            <Box>
              <Heading variant='h2'>What is STAR?</Heading>
              <Box>
                <h3>
                  STAR - Speech & Text Applied Research, is a research lab for
                  artificial intelligence at Mohamed bin Zayed University of
                  Artificial Intelligence (MBZUAI). STAR is committed to
                  progress in AI research, and is a place where like-minded
                  people can come to work together in the pursuit of knowledge,
                  understanding and eventually solutions to the many challenges
                  of AI development.
                </h3>
              </Box>
            </Box>
          </Card>
        </Main>
      </Stack>
      <Hero
        pt={4}
        pb={5}
        sx={{
          background: t =>
            `linear-gradient(
              0deg,
              ${t.colors.omegaLighter},
              ${t.colors.background}
            )`
        }}
      >
        <Divider space={3} />
        <Box sx={{ position: `relative`, zIndex: 3 }}>
          <Box sx={{ display: [`none`, `block`] }}>
            <Categories
              categories={categories}
              variant='horizontal'
              omitTitle
            />
            <Divider />
          </Box>

          <CardList
            nodes={featuredPosts.nodes}
            variant={['horizontal-hero']}
            limit={3}
            omitFooter
            slider
            autoPlay
            fade
            arrows={false}
            controlPosition='bottom'
            ref={sliderRef}
            loading='eager'
          />
          <Box sx={{ display: [`none`, null, `block`] }}>
            <Divider />
            <CardList
              nodes={featuredPosts.nodes}
              variant={['horizontal-md', 'horizontal-aside']}
              limit={3}
              columns={[1, 0, 3]}
              omitCategory
              asNavFor={sliderRef}
              loading='eager'
            />
          </Box>
        </Box>
      </Hero>

      {/* <Divider />
      <Stack>
        <Main>
          <CardList
            nodes={featuredPosts.nodes}
            variant={['horizontal-md', 'horizontal', 'horizontal', 'vertical']}
            limit={3}
            skip={3}
            columns={[1, 2, 3]}
            omitMedia
            title="Editor's Pick"
          />
        </Main>
      </Stack> */}
      <Divider />
      <Stack
        effectProps={{ effect: false }}
        title='Recent STAR Projects'
        direction={[`column`, null, null, `row`]}
      >
        <Main
          sx={{
            display: [`block`, null, null, `flex`]
          }}
        >
          <CardList
            nodes={recentPosts.nodes}
            variant={['vertical-cover']}
            limit={2}
          />
        </Main>
        <Divider space={2} />
        <Main
          sx={{
            mx: [0, null, null, 3],
            display: [`block`, null, null, `flex`]
          }}
        >
          <CardList
            nodes={recentPosts.nodes}
            variant={['horizontal-md', 'horizontal', 'horizontal', 'vertical']}
            limit={3}
            skip={2}
          />
        </Main>
        <Divider space={2} />
        <Main>
          <CardList
            nodes={recentPosts.nodes}
            variant={[
              'horizontal-md',
              'horizontal-md',
              'horizontal',
              'horizontal-aside'
            ]}
            limit={4}
            skip={3}
            columns={[1, 2, 1, 1]}
          />
        </Main>
      </Stack>
      <Divider />
      {/* <Hero wide>
        <CardList
          nodes={featuredPosts.nodes}
          variant='horizontal-cover-wide'
          limit={4}
          skip={6}
          slider
          autoPlay
          fade
          dots={false}
          controlPosition='bottom'
        />
      </Hero> */}
      <Divider />
      {/* {posts.group.length &&
        posts.group.map((group, index) => (
          <React.Fragment key={`${group.categoryName}.list`}>
            {index % 2 === 0 ? (
              <Stack
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    nodes={group.nodes}
                    variant={['horizontal-md', 'horizontal']}
                    limit={6}
                    columns={[1, 2, 3, 3]}
                    omitCategory
                  />
                </Main>
              </Stack>
            ) : (
              <Stack
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    nodes={group.nodes}
                    variant={['horizontal-md', 'horizontal']}
                    limit={6}
                    columns={[1, 1, 2]}
                    omitCategory
                  />
                </Main>
              </Stack>
            )}
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
        ))} */}
      <Divider space={5} />
      <Stack>
        <Main>{services.mailchimp && <NewsletterExpanded />}</Main>
      </Stack>
    </Layout>
  )
}

export default Posts
